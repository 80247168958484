@import "~open-sans-fontface/open-sans.css";
@import "~lato-font/css/lato-font.css";
@import "~react-popupbox/dist/react-popupbox.css";
@import "~react-daterange-picker/dist/css/react-calendar.css";

body {
    background-color: #FAFAFA;
}

html {
    font-family: "Open Sans", sans-serif;
}

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

h1, h2, h3, h4, h5, h6 {
    font-family: Lato, sans-serif;
    font-weight: normal;
    color: #707070;
    margin-top: 0;
}

h1 {
    font-size: 28px;
}

h2 {
    font-size: 18px;
}

a {
    font-size: 13px;
    color: #3B86FF;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.popupbox{
    overflow-y: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    align-items: center;
    max-width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    box-sizing: border-box;
}

.popupbox-wrapper{
    position: absolute;
    top: 0;
    box-sizing: border-box;
    margin: 70px auto;
    overflow: visible;
}
.popupbox-content{
    overflow: visible;
}

.Popover .Popover-tipShape{
    fill: white;
    border: 1px solid #F2F2F2;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
    border-radius:3px;
}
.Popover .Popover-body{
    border: 1px solid #F2F2F2;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
    background-color: white;
}
